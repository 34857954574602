<template>

  <div :class="'cmnt ' + type">

    <label v-if="label">{{ label }}</label>

    <textarea 
      ref="elTextarea"
      :placeholder="placeholder" 
      cols="30" 
      :rows="rows" 
      v-model="editedText"
      @keyup="change"
      @input="handleAutoResize"
    ></textarea>

    <div class="acts">
      <div v-if="enableUploader && editedPhotos.length < 5" :class="'photo_ic ' + (isUploadingPhotosCount ? 'floading' : '')">
        <UiUploader
          @changed="handleAddPhoto"
          @errored="handleErroredPhoto"
          @uploaded="handleUploadedPhoto"
          :limit="5"
          :url="'/uploads/media'"
          :url-data="props.uploaderUrlData"
          :enable-buttons="false"
        ><i class="icon-attachment"></i></UiUploader>
      </div>
      <div class="emoji_ic" @click="handleEmoji"><i class="icon-smile"></i></div>
    </div>    
    
    <div class="emoji_cn" v-if="emojiActive">
      <span
      v-for="(e,ek) in emoji"
      :key="ek"
      @click="insertEmoji(e)"
      >{{ e }}</span>        
    </div> 
      
  </div>

</template>


<script setup lang="ts">


interface Photo {
  id: number,
  size_big: string
  size_m: string
  size_s: string
  video_id: number | null
}

interface PageProps {
  text: string,
  type?: string,
  label?: string,
  rows?: number,
  placeholder?: string,
  max?: number,
  focusMounted?: boolean
  photos?: Photo[],
  enableUploader?: boolean,
  enableAutoHeight?: boolean,
  uploaderUrlData?: any
}

const props = withDefaults(defineProps<PageProps>(), {
  text: '',
  type: '',
  rows: 10,
  placeholder: '',
  focusMounted: false,
  photos: () => [] as Photo[],
  enableUploader: false,
  enableAutoHeight: false,
  uploaderUrlData: {}
})

const emits = defineEmits(['change:text', 'addPhoto'])

const editedText = ref(props.text)
const editedPhotos = ref(props.photos)
const isUploadingPhotosCount = ref(0)
const elTextarea = ref(null)
const emojiActive = ref(false)
const emoji = ref([
  "😀","😬","😁","😂","😃","😄","😅","😆","😇","😉","😊","😋","😌","😍","😘","😗","😙","😚","😜","😝","😛","😎","😏","😶","😐","😑","😒","😳","😞","😟","😠","😡","😔","😕","😣","😖","😫","😩","😤","😮","😱","😨","😰","😯","😦","😧","😢","😥","😪","😓","😭","😵","😲","😷","😴","💤","💩","😈","👿","👹","👺","💀","👻","👽","😺","😸","😹","😻","😼","😽","🙀","😿","😾","🙌","👏","👋","👍","👊","✊","👌","✋","💪","🙏","👆","👇","👈","👉"
])


const handleAutoResize = function(e: Event) {
  const target = e.target as HTMLTextAreaElement;
  autoResize(target)
}

const autoResize = function(target: HTMLTextAreaElement) {
  if (!props.enableAutoHeight) return
  if(!target) return
  target.style.height = 'auto';
  target.style.height = target.scrollHeight + 'px';
}

const change = () => {
  if (props.max && editedText.value.length > props.max) {
    editedText.value = editedText.value.substring(0, props.max)
  }
  emits('change:text', editedText.value)
}

const insertEmoji = (text: string) => {
  const scrollPos = elTextarea.value.scrollTop
  let strPos = 0
  const br = ((elTextarea.value.selectionStart || elTextarea.value.selectionStart == '0') ?
    "ff" : (document.selection ? "ie" : false))
  if (br == "ie") {
    elTextarea.value.focus()
    const range = document.selection.createRange()
    range.moveStart('character', -editedText.value.length)
    strPos = range.text.length
  } else if (br == "ff") strPos = elTextarea.value.selectionStart

  const front = (editedText.value).substring(0, strPos)
  const back = (editedText.value).substring(strPos, editedText.value.length)
  editedText.value = front + text + back
  strPos = strPos + text.length
  setTimeout(() => {

    if (br == "ie") {
      elTextarea.value.focus()
      const range = document.selection.createRange()
      range.moveStart('character', -editedText.value.length)
      range.moveStart('character', strPos)
      range.moveEnd('character', 0)
      range.select()
    } else if (br == "ff") {
      elTextarea.value.selectionStart = strPos
      elTextarea.value.selectionEnd = strPos
      elTextarea.value.focus()
    }

    elTextarea.value.scrollTop = scrollPos

  }, 100)

  change()
}

const handleEmoji = () => {
  emojiActive.value = !emojiActive.value
}
 

const handleAddPhoto = (photo: Photo) => {
  isUploadingPhotosCount.value++
}

const handleUploadedPhoto = (photo: Photo) => {
  isUploadingPhotosCount.value--
  emits('addPhoto', photo)

}

const handleErroredPhoto = (photo: Photo) => {
  isUploadingPhotosCount.value--
}


watch(() => props.text, (val) => {
  editedText.value = val
})

watch(() => props.photos, (val) => {
  editedPhotos.value = val
})


onMounted(() => {
  
  if (import.meta.client && props.enableAutoHeight) {
    autoResize(elTextarea.value)
  }
})
 
</script>
   

<style scoped>
.cmnt{
  position: relative;
}
.acts{
  display: flex;
  align-items: center;
  gap: 0.6rem;
  position: absolute;
  right: 10px;
  margin-top: -40px;
}
.emoji_ic{
  font-size: 1.5rem;      
  cursor: pointer;
}
.photo_ic{
  font-size: 1.5rem;     
  cursor: pointer;
}
.emoji_cn{
  margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
}
.emoji_cn > span{
  font-size: 1.4rem;
  cursor: pointer;
  transition: 300ms;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emoji_cn > span:hover{
  font-size: 1.9rem;
}


@media (max-width: 768px) {
   .emoji_ic,
   .emoji_cn{
     display: none;
   }
}

</style>
